.dropdown-new-btn button {
    background: #1a73e8;
    height: 35px !important;
}

.dropdown-new-btn:last-child {
    color: #1a73e8;
    background-color: white !important;
}

.menu-item-for-new svg{
    margin-bottom: 4px;
}

.menu-for-new li {
    color: #1a73e8;
}

.ant-dropdown {
    z-index: 10000;
}