.web-assistant-input-field-span-8 {
  width: 25%;
}

.web-assistant-input-field-span-8-div {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
}

.web-assistant-input-field-span-12 {
  width: 45%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.web-assistant-input-field-span-24 {
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
}

.web-assistant-input-field-icon {
  width: 250px;
  margin: auto;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.web-assistant-label-text {
  height: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 1032px) and (min-width: 990px) {
  .web-assistant-label-text {
    font-size: 12px;
  }
}

.vertical-divider {
  height: -webkit-fill-available;
}

.remove-flex {
  flex: none !important;
}

/* .icon-picker div div {
  margin-top: 0px !important;
} */

.icon-picker {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .icon-picker {
    margin-top: 20px;
  }
}

@media screen and (min-width: 992px) {
  .icon-picker {
    margin-left: 30px;
  }
}

.skeleton-web-assistant {
  width: 100%;
  height: 380px;
  border: 1px solid gray;
  border-radius: 5px;
}

.ant-skeleton.skeleton-input-topic-tree {
  width: 80% !important;
  margin-left: 10px;
  margin-top: 15px;
  height: 25px !important;
}

.ant-skeleton.skeleton-input-topic-tree span {
  width: 130px !important;
  min-width: 130px !important;
  height: 25px;
}

.skeleton-content {
  margin-left: 10px;
  margin-top: 15px;
}

.skeleton-content-in-assistant {
  margin-left: 10px;
  width: 80%;
  margin-top: 20px;
}

.sketch-holder {
  margin-top: 10px;
  margin-left: -45px;
  flex-direction: column;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 10;
  width: 250px;
  background-color: white;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.sketch-holder-button {
  margin-top: 10px;
}

.slider {
  position: absolute;
  z-index: 9;
  bottom: 1px;
  overflow-y: hidden;
}

.slider-header {
  height: 40px;
  width: 200px;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.make-slider-right {
  right: 30px;
}

.make-slider-left {
  left: 30px;
}

.popup {
  position: absolute;
  z-index: 9;
  bottom: 20px;
  display: flex;
  align-items: flex-end;
}

.popup-header {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.make-popup-right {
  right: 20px;
}

.make-popup-left {
  left: 20px;
}

.slider-body {
  height: 280px;
  width: 200px;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s;
  transition-timing-function: ease;
}

.sketch-picker {
  box-shadow: none !important;
}

.slider-body div .ant-skeleton {
  opacity: 1;
  transition: all 0.4s;
}

.active-slider-body div .ant-skeleton {
  opacity: 0;
  transition: none;
}

.icon-in-popup {
  font-size: 30px;
  display: flex;
  margin-top: 1px;
}

.settings-item-title {
  font-weight: 500 !important;
}

.popup-body-right {
  display: flex;
  height: 320px;
  width: 200px;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;border: 1px solid gray;
  margin-right: 20px;
}

.popup-body-left {
  display: flex;
  height: 320px;
  width: 200px;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;border: 1px solid gray;
  margin-left: 20px;
}

#editor-sonat-web-assistant-js-trigger-help-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.editor-sonat-web-assistant-drawer-right {
  position: absolute;
  top: 0;
  right: -100px;
  width: 180px;
  height: 99.8%;
  background-color: #f1f1f1;
  transition: right 0.3s ease-in-out;
  z-index: 9999;
  display: none;
}

.editor-sonat-web-assistant-drawer-left {
  position: absolute;
  top: 0;
  left: -100px;
  width: 180px;
  height: 99.8%;
  background-color: #f1f1f1;
  transition: left 0.3s ease-in-out;
  z-index: 9999;
  display: none;
}

.editor-sonat-web-assistant-drawer-button-right{
  position: absolute;
  top: calc(50% - 50px);
  right: 0;
  width: 30px;
  min-height: fit-content;
  padding: 10px 0px;
  /* transition: right 0.3s ease-in-out; */
  z-index: 9999;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px,
  rgba(0, 0, 0, 0.24) 0px -3px 3px,
  rgba(0, 0, 0, 0.24) -3px 0px 3px;
  writing-mode: vertical-rl;
}

.editor-sonat-web-assistant-drawer-button-right.open{
  right: 180px;
}

.editor-sonat-web-assistant-drawer-button-left{
  position: absolute;
  top: calc(50% - 50px);
  left: 0;
  width: 30px;
  min-height: fit-content;
  height: 50px;
  padding: 10px 0px;
  /* transition: right 0.3s ease-in-out; */
  z-index: 9999;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 3px,
  rgba(0, 0, 0, 0.24) 0px -3px 3px,
  rgba(0, 0, 0, 0.24) 3px 0px 3px;
  writing-mode: vertical-lr;
}

.editor-sonat-web-assistant-drawer-button-left.open{
  left: 180px;
}

.editor-sonat-web-assistant-drawer-right.open {
  right: 0;
  display: block;
}

.editor-sonat-web-assistant-drawer-left.open {
  left: 0;
  display: block;
}

.editor-sonat-web-assistant-side-drawer-close-icon-right {
  position: absolute;
  top: 11px; 
  right: 11px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-color: rgba(95, 99, 105, 0.7);
  border-radius: 20%;
  transition: background-col or 0.3s ease;
}

.editor-sonat-web-assistant-side-drawer-close-icon-left {
  position: absolute;
  top: 11px; 
  left: 7px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-color: rgba(95, 99, 105, 0.7);
  border-radius: 20%;
  transition: background-col or 0.3s ease;
}

.editor-sonat-web-assistant-popup-close-icon-right {
  position: absolute;
  top: 11px; 
  right: 7px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-color: rgba(95, 99, 105, 0.7);
  border-radius: 20%;
  transition: background-col or 0.3s ease;
}

.editor-sonat-web-assistant-popup-close-icon-left {
  position: absolute;
  top: 11px; 
  left: 7px;
  color: #fff;
  font-size: 30px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  background-color: rgba(95, 99, 105, 0.7);
  border-radius: 20%;
  transition: background-col or 0.3s ease;
}

.editor-sonat-web-assistant-close-icon:hover {
  background-color: #fff;
  color:rgba(95, 99, 105, 0.7);
}

.js-trigger-code code {
  margin: 0;
}

#editor-sonat-web-assistant-help-drawer{
  border: 1px solid rgb(178, 178, 178);
  border-radius: 2px;
}