.ck-editor__editable_inline {
  height: 100vh;
}

@media only screen and (max-width: 1026px) {
  #fadeshow1 {
    display: none;
  }
}

.ck.ck-dropdown__panel {
  max-height: 60vh;
  max-height: var(--ck-editor-dropdown-max-height, 60vh);
  overflow-y: auto;
}

.topic-link {
  color: #0000ee;
  text-decoration: underline;
}

.custom-heading {
  font-size: calc(1.325rem + .9vw);
  margin-top: 0;
  margin-bottom: .5em;
  color: rgba(0,0,0,.85);
  font-weight: 500;
}

@media (min-width: 1200px) {
  .custom-heading {
    font-size: 2rem;
  }
}

.template-box {
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 10px
}

.template-box::before {
  font: normal normal normal 24px Material Icons;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga";
  font-feature-settings: "liga";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-transform: none;
  word-wrap: normal;
  display: flex;
  float: left;
  align-items: center;
  padding: 15px 0;
  margin-right: 5px;
}

.template-box .template-box-title {
  min-width: 65px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding: 15px 0;
  font-weight: bold;
}

.template-box .template-box-description {
  flex: 1 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
}

.template-box .template-box-description p {
  margin: 0;
}

.template-box.example-template-box {
  background-color: #00ff005e;
  color: #027202;
}

.template-box.example-template-box::before {
  content: "tips_and_updates";
}

.template-box.note-template-box {
  background-color: #e1f5fe;
  color: #01579b;
}

.template-box.note-template-box::before {
  content: "star";
}

.template-box.caution-template-box {
  background-color: #feefe3;
  color: #bf360c;
}

.template-box.caution-template-box::before {
  content: "report";
}

.template-box.warning-template-box {
  background-color: #fce8e6;
  color: #d50000
}

.template-box.warning-template-box::before {
  content: "warning";
}

.template-box.danger-template-box {
  background-color: #df040487;
  color: #930101;
}

.template-box.danger-template-box::before {
  content: "error";
}


.template-box.extra-template-box {
  background-color: #ead1dc;
  color: #800080d4;
}

.template-box.extra-template-box::before {
  content: "arrow_right_alt";
}

#CKEditorWrapper section[data-component="true"] {
  position: relative;
}

.edit-component-button {
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  background-color: #185abc;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 4px 16px;
  border: 1px solid transparent;
  opacity: 0;
  transition: opacity 0.3s;
}

.ck-content .table>:not(:last-child)>:last-child>* {
  border-bottom: unset;
}

@font-face {
  font-family: 'Barcode';
  src: url('../../assets/font/Free3of9.woff2') format('woff2'),
      url('../../assets/font/Free3of9.woff') format('woff');
}