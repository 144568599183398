.tableHeader > div{
    text-align: center;
    padding: 10px;
    font-weight: bold;
 }

.tableHeader > div:first-child{
   text-align: left!important;
}

.tableHeader > div:last-child{
    border: none!important;
}

.tableRow:nth-of-type(even){
    background-color: #f0f0f0;
}

.tableRow > div{
    text-align: center;
    padding: 10px;
}

.tableRow > div:first-child{
   text-align: left!important;
}

.tableRow > div:last-child{
    border: none!important;
}