#image-gallery, #image-gallery .uppy-Dashboard-inner, .uppy-Root.image-gallery {
    width: 100%!important;
}

#image-gallery .uppy-Dashboard-AddFiles-list {
    max-width: 400px!important;
    margin-top: 45px;
}
.uppy-Dashboard-AddFiles-title {
    margin-top: -80px!important;
}

.uppy-Dashboard-AddFiles-info {
    bottom: 50px!important;
}