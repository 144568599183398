.org-settings-tabs
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane
  > div
  > div:first-child {
  border: 1px solid rgb(240, 240, 240);
  border-radius: 2px;
}

.settings-item-title {
  font-weight: 500 !important;
}

.settings-icon-container-show {
  width: 10%;
}

.settings-icon-container-hide {
  display: none;
}

.settings-detail-container-show-partly {
  width: 90%;
}

.settings-detail-container-show-full {
  width: 100%;
}
.advanced-settings-select {
  width: 350px;
}
@media (max-width: 550px) {
  .advanced-settings-select {
    width: calc(100% - 20px);
  }
}
