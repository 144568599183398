.previewContent {
    background-color: #e8eaed;
}

.previewContent .MuiSkeleton-root {
    background-color: #b0b5bb !important
}

.light .MuiSkeleton-root {
    background-color: #b0b5bbb3 !important
}

.MuiSkeleton-root::after {
    animation: none !important;
    background: none !important;
}

.MuiExpansionPanelSummary-content {
    margin: 0 !important
}

.child {
    margin-left: 10px;
}

.secondChild {
    margin-left: 20px;
}