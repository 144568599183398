.flex-col{
    display: flex;
    flex-direction: column ;
    justify-content:center ;
    align-items:center;
    width: 33%;
}

.flex-col-full{
    display: flex;
    flex-direction: column ;
    justify-content:center ;
    align-items:center;
    width: 100%;
}

.item-title{
    font-weight: 400 !important;
  }

.container-box-shape{
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 12px;
    margin: 5px;
}