.active {
    border-color:#2979ff!important;
    transition: all 0.5s;
    opacity: 1;
}

.deactive {
    transition: all 0.5s;
    opacity: 0;
}

.input-base{
    margin-left: 8px;
    flex: 1;
    font-size: 14px !important;
}

.settings-item-title{
  font-weight: 500 !important;
}