html {
  height: 100%;
}

body * {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: scroll;
}

code {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif, monospace !important;
}

#root {
  height: 100%;
}

.app-layout {
  height: 100%;
}

.main {
  display: flex;
  height: calc(100% - 64px);
}

.content {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 1240px) {
  #fadeshow {
    display: none;
  }
}

.hide {
  display: none;
}

.ReactVirtualized__Grid.ReactVirtualized__List::-webkit-scrollbar {
  width: 10px;
}

.ReactVirtualized__Grid__innerScrollContainer {
  width: 100% !important;
  max-width: none !important;
  overflow: visible !important;
}

.ant-select-item-option-selected {
  background-color: #1a73e8 !important;
  color: #ffffff !important;
  font-weight: 500 !important;
}

.ant-select-item-option-state .anticon-check {
  color: #fff !important;
}

.ant-select-selector {
  padding: 5px !important;
  border-radius: 6px !important;
}

.label {
  position: relative;
  display: inline-flex;
  align-items: center !important;
  max-width: 100%;
  height: 32px !important;
  color: #000000d9 !important;
  font-size: 14px !important;
}

.ant-form-item {
  margin-bottom: 0 !important;
}

.ant-select-single .ant-select-selector {
  align-items: center !important;
}

.mb-3 {
  margin-bottom: 10px !important;
}

.ant-list-sm .ant-list-item {
  border-bottom: none !important;
  padding: 5px 16px !important;
}

.ant-list-item-meta {
  display: list-item !important;
  list-style: disc !important;
}

.ant-list-item-meta .ant-list-item-meta-content {
  display: inline !important;
}

.ant-list-item-meta::marker {
  color: #c4c4c4 !important;
}

.ant-select-dropdown {
  z-index: 9999 !important;
}

.ant-tooltip {
  z-index: 10000 !important;
  max-width: 400px !important;
}

.ck-content {
  font-size: 16px;
}

.ck-content > h1 {
  font-size: 40px;
}

.ck-content > h2 {
  font-size: 1.5em !important;
}

.ck-content > h1, .ck-content > h2, .ck-content > h3, .ck-content > h4, .ck-content > h5, .ck-content > h6 {
  font-weight: 500;
}

.ck-content mark {
  padding: 0 !important;
}

.custom-heading {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  display: inline;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 500;
}

.filter-section,
.filter-section label {
  height: 38px;
}

.filter-section label {
  background-color: #fafafa;
}

.filter-section span {
  display: flex;
  align-items: center;
}

.publish-buttons label::before {
  content: none !important;
}

.ck.ck-dropdown__panel {
  overflow-y: visible !important;
}

.ant-switch-loading-icon {
  transform: none !important;
  position: static !important;
}

.publish-buttons {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500 !important;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}

.publish-buttons .ant-btn {
  border: none !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500 !important;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.publish-menu-item {
  border: none !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500 !important;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: left;
}

.publish-buttons .ant-btn:first-child {
  border-right: 2px solid rgba(26, 115, 232, 0.5) !important;
}

.publish-buttons.disable-publish .ant-btn:first-child,
.publish-buttons.disable-publish .ant-btn:first-child * {
  pointer-events: none;
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25) !important;
}

.topic-schema-type .ant-select-selection-item {
  display: flex;
  align-items: center;
}

.label {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

@media screen and (max-width: 1746px) {
  .publish-buttons .ant-btn {
    padding: 4px 8px !important;
  }
}

@media screen and (max-width: 1820px) {
  .responsive-publish-text {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 1240px) {
  .responsive-publish-text {
    display: none !important;
  }
}

.rec-item-wrapper > div {
  width: 100%;
}

.rec-dot_active {
  background-color: rgb(24 144 255) !important;
  box-shadow: none !important;
}

.rec-pagination button:hover {
  box-shadow: none !important;
}

.topic-tree-header .MuiCardHeader-action {
  width: 100%;
}

.hide-alert .ant-form-item-explain.ant-form-item-explain-connected {
  display: none !important;
}

.ant-tree-draggable-icon {
  display: none !important;
}

.ant-tree.ant-tree-block-node
  .ant-tree-list-holder-inner
  .ant-tree-node-content-wrapper {
  margin-right: 0;
  margin-left: 0;
  padding-left: 0;
}

.component-card {
  border: none !important;
  z-index: 999;
}

.component-card .ant-card-head {
  border-bottom: none !important;
}

.component-card .ant-card-body {
  height: calc(100% - 58px);
  overflow: scroll;
}

.component-card .ant-card-extra {
  display: none;
}

.content-box div[data-rbd-droppable-id='component-editor'] {
  max-height: calc(100vh - 150px);
  overflow-y: scroll;
}

.react-grid-layout.layout {
  margin-top: 15px;
  display: flex !important;
  flex-wrap: wrap !important;
}

.full-height-flex-box {
  box-sizing: border-box;
  border: 1px solid transparent !important;
  position: relative;
}

.full-height-flex-box:hover {
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
}

.full-height-flex-box:hover .component-card .ant-card-extra {
  display: flex;
}

.full-height-flex-box .component-card {
  border: none;
}

.editor-card .ant-card-body {
  padding: 0 !important;
}

.full-height-flex-box {
  max-width: 100%;
}

.full-height-flex-box,
.full-height-flex-box > div,
.full-height-flex-box > div > div,
.full-height-flex-box > div > div > div,
.full-height-flex-box > div > div > div > div,
.full-height-flex-box > div > div > div > div > div {
  flex: 0 1;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after {
  display: inline-block;
  margin-right: 4px;
  color: #000;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.access-documents-selector .ant-select-selector {
  flex-wrap: nowrap !important;
  overflow: scroll !important;
  border: none !important;
}

.access-documents-selector .ant-select-selection-item {
  align-items: center;
}

.access-documents-selector .ant-select-selection-item-remove {
  display: flex !important;
}

.content-box {
  max-width: 100%;
  width: 100%;
}

.side-bar {
  max-width: 375px;
  width: 100%;
}

#component-selector {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}

@media screen and (min-width: 1830px) {
  .content-box {
    max-width: 1100px;
    width: 1100px;
  }
}

@media screen and (min-width: 1740px) and (max-width: 1829px) {
  .content-box {
    max-width: 1000px;
    width: 1000px;
  }
}

@media screen and (min-width: 1600px) and (max-width: 1739px) {
  .content-box {
    max-width: 900px;
    width: 900px;
  }
}

@media screen and (min-width: 1536px) and (max-width: 1599px) {
  .content-box {
    max-width: 800px;
    width: 800px;
  }
}

@media screen and (min-width: 1506px) and (max-width: 1536px) {
  .content-box {
    max-width: 900px;
    width: 900px;
  }

  #component-selector {
    width: 275px;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1505px) {
  .content-box {
    max-width: 850px;
    width: 850px;
  }

  #component-selector {
    width: 235px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .content-box {
    max-width: 800px;
    width: 800px;
  }
}

@media screen and (min-width: 1180px) and (max-width: 1299px) {
  .content-box {
    max-width: 700px;
    width: 700px;
  }
}

@media screen and (min-width: 1097px) and (max-width: 1179px) {
  .content-box {
    max-width: 600px;
    width: 600px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1097px) {
  .content-box {
    max-width: 550px;
    width: 550px;
  }
}

@media screen and (min-width: 991px) and (max-width: 1023px) {
  .content-box {
    max-width: 400px;
    width: 400px;
  }
}

@media screen and (min-width: 900px) and (max-width: 990px) {
  .content-box {
    max-width: 350px;
    width: 350px;
  }
}

@media screen and (min-width: 768px) and (max-width: 899px) {
  .content-box {
    max-width: 250px;
    width: 250px;
  }
}

.ck-content h1 {
  max-width: 100% !important;
}

.ck-content .table {
  width: auto;
}

.ck-content .table.ck-widget table {
  table-layout: fixed;
  width: inherit;
}

.width-transition {
  transition: width 0.3s;
}

.side-collapse-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn:hover a,
.ant-btn:focus a {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.ant-space-item {
  display: flex;
  align-items: center;
}

.menu-item-span {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 950px) {
  .desktop-navbar-menu-builder {
    display: none;
  }
}

.ant-dropdown-menu-submenu-popup ul {
  margin-left: 0 !important;
}

.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu {
  padding-top: 0 !important;
}

.ant-menu-root.ant-menu-inline > li > .ant-menu-submenu-title {
  padding-left: 0 !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none !important;
}

.ant-menu-inline {
  width: 300px !important;
  min-width: 250px;
}

.ant-menu-sub.ant-menu-inline {
  background: none !important;
}

.ant-dropdown-menu-submenu-expand-icon {
  display: none;
}

hr {
  height: 0;
  background-color: inherit;
}

.ant-dropdown {
  width: max-content !important;
}

.more-menu-li {
  padding: 10px 16px !important;
}

.more-menu-icon-item {
  min-width: 56px !important;
}

.menu-item-for-new {
  margin-right: 8px !important;
}

@media screen and (min-width: 600px) {
  .MuiTab-root {
    min-width: 160px !important;
  }
}

.side-bar {
  z-index: 0;
}

.monaco-list-rows .monaco-list-row .main {
  height: auto !important;
}

#component-selector {
  position: relative;
  top: 32px;
}

#component-selector > div {
  overflow-x: hidden;
}

@media screen and (max-width: 1400px) {
  #component-selector {
    width: 200px;
  }

  #component-selector > div {
    flex-flow: column;
  }

  #component-selector > div > div {
    width: auto !important;
  }
}

#component-selector .droppable-element .ant-card-body {
  padding: 24px 12px;
}

#monaco-yaml-editor-box {
  width: 100%;
}

.droppable-element.ant-col,
.droppable-element.ant-col .ant-card {
  height: 100%;
}

.drag-indicator {
  padding: 10px;
  z-index: 1000;
  position: absolute;
  cursor: grab;
  transition: all 0.3s;
  display: none;
}

.full-height-flex-box:hover .drag-indicator {
  display: block;
}

.drag-indicator:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.ant-modal-mask,
.ant-modal-wrap {
  z-index: 999 !important;
}

.preview-component-modal {
  min-width: 400px !important;
  width: fit-content !important;
}

#main-section {
  z-index: 999;
}

.MuiModal-root {
  z-index: 9999 !important;
}

.component-selector-modal.ant-modal {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.component-selector-modal .ant-modal-body {
  min-width: auto;
  min-height: auto;
  max-height: 650px;
  overflow-y: scroll;
}

.ant-tree-switcher {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-menu {
  background: inherit;
  max-height: 380px !important;
  overflow-y: auto !important;
}

table {
  border: none !important;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.new-topic-modal .ant-modal-body {
  min-height: 100px !important;
}

.ant-tree .ant-tree-treenode {
  align-items: center !important;
}

@media screen and (max-width: 1199px) {
  .hide-on-xlarge {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  .hide-on-large {
    display: none !important;
  }
  .show-on-large {
    display: flex !important;
  }
}

.ant-menu-submenu .ant-menu-sub .ant-menu-item .ant-btn {
   justify-content: left;
}

.logo-favicon-container .ant-btn .anticon {
  justify-content: center;
}

.ant-pagination-item-container {
  width: 100%;
}

.ant-pagination-item-container .ant-pagination-item-ellipsis {
  display: flex !important;
  left: 4px !important;
}

[class^="ant-"], [class*=" ant-"] {
  border-radius: 6px;
}

.ant-radio-button-wrapper:first-child {
  border-radius: 6px 0 0 6px;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 6px 6px 0;
}

.ant-tooltip-placement-top {
  padding-bottom: 0;
}

.ant-tooltip-placement-bottom {
  padding-top: 5px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
  bottom: 1px !important;
  transform: translateX(-50%) translateY(100%) rotate(180deg) !important;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: 6px !important;
}

.ant-input-textarea-show-count::after {
  display: none;
}

.ant-switch {
  border-radius: 100px;
}
