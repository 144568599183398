.disabled {
    filter: opacity(0.5);
    pointer-events: none;
}

.expanded-summary-feedback {
    padding-left: 0!important;
    min-height: auto!important;
    padding: 10px 0!important;
    cursor: auto!important;
}

.expanded-summary-feedback .MuiExpansionPanelSummary-expandIcon {
    position: absolute;
    right: 15px;
    cursor: pointer!important;
}

.flex-resp {
    display: flex;
}

@media screen and (max-width: 767px) {
    .flex-resp {
        display: block;
    }
}