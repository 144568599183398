.error .ant-collapse-header, .warning .ant-collapse-header, .success .ant-collapse-header {
    background-color: #fff!important;
}

.error, .error * {
    border-color: #dc3545!important;
}

.warning, .warning * {
    border-color: #ffc107!important;
}

.success, .success * {
    border-color: #28a745!important;
}