.tooltip-icon-settings {
  font-size: 9px;
  margin-left: 4px !important;
}

.div-fit-2-input {
  display: flex;
  justify-content: space-between;
}

.in-div-2-inputs {
  display: block;
  width: 48%;
}

.image-icon-selector-crop-logo {
  display: flex;
  position: relative;
  background-color: #1a73e8;
  color: white !important;
  left: -15px;
  bottom: 14px;
  border-radius: 6px;
  border: 1px solid gray;
  width: fit-content;
  padding: 3px 10px;
}

.edit-icon-crop-logo {
  margin-top: 4px;
  margin-right: 4px;
}

.upload-crop-logo div span {
  display: flex;
}

.div-for-upload {
  margin-top: 10px;
}

.div-for-upload span .ant-upload {
  width: 150px !important;
  height: 150px !important;
  border-radius: 10px !important;
}

.avatar-uploader .ant-upload {
  pointer-events: none;
}

.avatar-uploader .ant-upload {
  border: none !important;
}

.avatar-uploader:hover .uploaded-image {
  transition: filter 0.5s;
  filter: brightness(50%);
}

.avatar-uploader:hover .preview-delete-icons {
  transition: opacity 0.5s;
  opacity: 1;
}

.preview-delete-icons {
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
}

.div-for-img-preview-delete {
  position: relative;
}

.preview-delete-icons {
  opacity: 0;
}

.icons-in-image {
  font-size: 24px;
  cursor: pointer;
  pointer-events: all;
}

#imageLogo {
  opacity: 0;
}

.imageLogoParent {
  display: none !important;
}

.delete-image-icon :hover {
  color: red !important;
  transition: 0.2s;
}

.delete-image-icon-display-none {
  display: none;
}

.image-preview-box:hover {
  transition: 0.2s;
  opacity: 0.5;
}

.image-preview-box:hover .delete-image-icon-display-none {
  transition: 0.2s;
  display: block;
}

/* .icons-in-image {
  filter: brightness(100%) !important;
} */

@media (max-width: 600px) {
  .settings-logo-container{
    margin: auto;
  }
}