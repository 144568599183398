.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    min-width: 400px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

@media (max-width: 700px) {
    .StripeElement {
        min-width: auto;
    }
}
  
.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}
  
.StripeElement--invalid {
    border-color: #eb1c26;
}
  
.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.card-errors {
    color: #eb1c26;
}

.title {
    margin-bottom: 10px;
}