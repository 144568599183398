#component-selector {
    position: fixed;
    top: 150px;
    right: 10px;
    max-width: 375px;
}

.component-help-box {
    position: absolute;
    right: 5px;
    bottom: 5px;
}