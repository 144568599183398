.folder-list-item {
    width: 275px;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 4px;
    background-color: #eeeeee;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.folder-list-item:last-child {
    margin-right: 0;
}

.folder-list-item:hover {
    background-color: #dedede;
}

.folder-list-item > div {
    display: flex;
    align-items: center;
}

.folder-list-item.disabled {
    cursor: auto;
    pointer-events: auto;
    background-color: #c0c0c0;
}