.delete-card .ant-card-head {
    padding-left: 0px;
    height: 50px;
    padding-top: 8px;
}

.delete-card .ant-card-body {
    padding-left: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.delete-card .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    padding-top: 0px;
    padding-bottom: 0px;
}

.delete-card .ant-card-head .ant-card-head-wrapper .ant-card-extra {
    padding-top: 0px;
    padding-bottom: 0px;
}

.delete-card .ant-card-head {
    padding-right: 0px;
}

.delete-card {
    padding: 0px 5px !important;
    border: 1px solid #989898 !important;
}

.delete-card .ant-card-head .ant-card-head-wrapper {
    top: 50%;
}

.settings-item-title{
  font-weight: 500 !important;
}