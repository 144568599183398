#publish-requests-history-topic-list .ant-tree-switcher {
    width: 0!important;
}

#publish-requests-history-topic-list .ant-tree-node-content-wrapper {
    margin: 0;
    padding: 0;
}

#publish-requests-history-topic-list .ant-tree-node-selected {
    line-height: 24px!important;
}

.publish-request-history-item {
    padding: 5px;
    display: flex;
    border-bottom: 1px solid #cacaca;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.publish-request-history-item .circle-icon {
    display: none;
    font-size: 1.25em;
}

.publish-request-history-item.selected .circle-icon {
    display: block !important;
}

.publish-request-history-item:hover .circle-icon {
    display: block;
}

.publish-request-history-item .time-line {
    position: absolute;
    right: 13px;
    width: 1px;
    background-color: #fff;
    z-index: 100;
}