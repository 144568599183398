.icon {
  margin-right: 20px;
  margin-top: 20px;
  width: 40px !important;
  height: 40px !important;
}

.icon-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.dialog .MuiDialog-paper {
  width: 600px !important;
  height: 70vh !important;
}

.active {
  color: #1a73e8;
}
