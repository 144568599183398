#faviconTooltip {
  display: none;
  opacity: 0;
  position: absolute;
  z-index: 9999;
  transition: all 0.3s;
  visibility: hidden;
  color: #fff;
  padding: 4px 8px;
  font-size: 0.75rem;
  max-width: 315px;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
  left: -35px;
}

.MuiTooltip-popper > div {
  font-size: 0.75rem !important;
}

#faviconTooltip img {
  width: 300px;
  border-radius: 4px;
}

.inputBox {
  display: flex;
}

#favicon {
  opacity: 0;
}

.faviconParent {
  opacity: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.delete-favicon-icon :hover {
  color: red !important;
  transition: 0.2s;
}

@media (max-width: 767px) {
  #faviconTooltip img {
    width: 150px;
  }

  .inputBox {
    display: block;
  }

  .inputBox > div {
    margin-top: 10px;
  }
}

.settings-item-title{
  font-weight: 500 !important;
}

@media (min-width: 600px) {
  .horizontal-divider-logo-favicon{
    display: none;
  }
  .vertical-divider-logo-favicon{
    display: block;
  }
}

@media (max-width: 600px) {
  .horizontal-divider-logo-favicon{
    display: block;
  }
  .vertical-divider-logo-favicon{
    display: none;
  }
  .logo-favicon-container{
    flex-direction: column;
  }
}