.layout {
    width: 100%;
    height: calc(100% - 40px);
  }
  
.dialog .MuiPaper-root {
    padding: 20px 30px 20px 20px;
    min-width: 400px;
  }

  .org-settings-tabs
  > .ant-tabs-content-holder
  > .ant-tabs-content
  > .ant-tabs-tabpane
  > div
  > div:first-child {
  border: 1px solid rgb(240, 240, 240);
  border-radius: 2px;
}

  