.custom-progress-bar {
    height: 10px;
    background-color: rgb(194, 191, 191);
    position: relative;
}

.progress-bar-step {
    position: absolute;
    top: 0;
    height: 10px;
    display: flex;
    justify-content: flex-end;
}

/* .progress-bar-step-label, .current-value {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #fff;
} */

.current-value {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
}