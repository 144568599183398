.tooltip-icon-settings {
  font-size: 9px;
  margin-left: 4px !important;
}

.div-fit-2-input {
  display: flex;
  justify-content: space-between;
}

.in-div-2-inputs {
  display: block;
  width: 48%;
}

.image-icon-selector {
  display: flex;
  position: relative;
  background-color: #1a73e8;
  color: white;
  left: -15px;
  bottom: 14px;
  border-radius: 6px;
  border: 1px solid gray;
  width: fit-content;
  padding: 3px 10px;
  cursor: pointer;
}

.edit-icon {
  margin-top: 4px;
  margin-right: 4px;
}

.settings-item-title{
  font-weight: 500 !important;
}