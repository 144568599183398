.container-box-shape{
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 12px;
    margin: 5px;
}

.container-box-shape-sm{
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    padding: 5px;
    margin-top: 4px;
}


.ant-table td {
  border: none;
}


  .item-title{
    font-weight: 400 !important;
  }